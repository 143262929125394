import { Route } from '@angular/router';

import { VltJobComponent } from './vlt-job.component';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';

export const VLT_JOB_ROUTE: Route = {
    path: 'vlt-job',
    component: VltJobComponent,
    data: {
        role: [],
        pageTitle: 'app.menu.vlt-job',
    },
    canActivate: [EciAuthGuard],
};
