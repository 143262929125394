import { Component, OnInit } from '@angular/core';
import { EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { Country } from './Country';
import { CountryManagementService } from './country-management.service';
import notify from 'devextreme/ui/notify';
import { FormBuilder } from '@angular/forms';
import DataSource from "devextreme/data/data_source";

const weekdays = [
    'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday',
];

@Component({
    selector: 'vltc-country-management',
    templateUrl: './country-management.component.html',
    styleUrls: ['./country-management.component.scss'],
})
export class CountryManagementComponent implements OnInit {
    weekdaysWithNames: any;
    timeZones: string[] = [];
    selectedWeekdays: string[] = [];
    country: Country;
    loading = true;
    countryForm: any;
    weekdays: any;
    plantDeliveryTimes: DataSource;
    warehouses = ['PIL', 'LUD', 'ROT', 'HUE', 'BLO', 'SOS', 'ROO', 'PIN', 'SES', 'VEN', 'ECHT'];

    constructor(
        public translateService: EciTenantAwareLanguageService,
        private readonly countryManagementService: CountryManagementService,
        private readonly formBuilder: FormBuilder) {
    }

    private static getSelectedWorkingDays(country: Country): any {
        const res: string[] = [];
        if (country.workingDayMonday) {
            res.push(weekdays[0]);
        }
        if (country.workingDayTuesday) {
            res.push(weekdays[1]);
        }
        if (country.workingDayWednesday) {
            res.push(weekdays[2]);
        }
        if (country.workingDayThursday) {
            res.push(weekdays[3]);
        }
        if (country.workingDayFriday) {
            res.push(weekdays[4]);
        }
        if (country.workingDaySaturday) {
            res.push(weekdays[5]);
        }
        if (country.workingDaySunday) {
            res.push(weekdays[6]);
        }
        return res;
    }

    ngOnInit() {
        this.weekdaysWithNames = this.getWeekdaysWithNames();
        this.getTimeZones();
        const getCountry = this.countryManagementService.getCountry();
        if (getCountry) {
            getCountry.subscribe(country => {
                this.country = country;
                this.weekdays = this.orderWeekdays(this.country);
                this.updateCountryForm(country);
                this.loading = false;
            });
        }
        this.countryForm = this.formBuilder.group({
            timeZone: [''],
            standardDeliveryDays: [''],
            extraDeliveryDays: [''],
            name: [''],
            selectedWeekdays: this.formBuilder.array,
        });
        this.plantDeliveryTimes = this.countryManagementService.getPlantMinDeliveryTimesForCountry();
    }

    onFormSubmit() {
        this.mapWorkingDaysToCountry(this.countryForm.value.selectedWeekdays);
        this.country.timeZone = this.countryForm.value.timeZone;
        this.country.standardDeliveryDays = +this.countryForm.value.standardDeliveryDays;
        this.country.extraDeliveryDays = +this.countryForm.value.extraDeliveryDays;
        this.country.name = this.countryForm.value.name;

        this.countryManagementService.updateCountry(this.country)
            .then((country: Country) => {
                this.updateCountryForm(country);
                notify(this.translateService.get('global.success'), 'success', 600);
            })
            .catch(() => notify(this.translateService.get('app.countryManagement.error'), 'error', 600));
    }

    onSaving() {
        this.countryManagementService.updateCountry(this.country)
            .then((country: Country) => {
                this.updateCountryForm(country);
                notify(this.translateService.get('global.success'), 'success', 600);
            })
            .catch(() => notify(this.translateService.get('app.countryManagement.error'), 'error', 600));
    }

    orderWeekdays(country: Country) {
        const weekdayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        const weekdays = country.weekdays.sort((a, b) => {
            return weekdayOrder.indexOf(a.weekday) - weekdayOrder.indexOf(b.weekday);
        });

        if (country.id === 'BE') {
            return weekdays.map(item => ({
                weekday: item.weekday,
                cutOffTime: item.cutOffTime,
                regularExtraDays: item.regularExtraDays,
                weekdayMap: item.weekdayMap,
                nl: item.weekdayMap?.['NL'] || null,
                fr: item.weekdayMap?.['FR'] || null,
            }));
        } else {
            return weekdays.map(item => ({
                weekday: item.weekday,
                cutOffTime: item.cutOffTime,
                regularExtraDays: item.regularExtraDays,
                displayValue: item.weekdayMap?.[country.id] || null,
                weekdayMap: item.weekdayMap,
            }));
        }
    }

    cellTemplate(container, options) {
        const noBreakSpace = '\u00A0';
        const text = (options.row.data.warehouses || []).map(element => options.column.lookup.calculateCellValue(element)).join(', ');
        container.textContent = text || noBreakSpace;
        container.title = text;
    }

    onValueChanged(cellInfo, e) {
        cellInfo.setValue(e.value);
    }

    getValue(cellInfo) {
        if (cellInfo.value != null) {
            return cellInfo.value;
        }
    }

    private updateCountryForm(country: Country) {
        this.countryForm.setValue({
            timeZone: country.timeZone || 'UTC',
            standardDeliveryDays: country.standardDeliveryDays.toString() || '3',
            extraDeliveryDays: country.extraDeliveryDays.toString() || '0',
            selectedWeekdays: CountryManagementComponent.getSelectedWorkingDays(country) || [],
            name: country.name || 'test1',
        });
        this.countryForm.markAsPristine();
        this.countryForm.markAsUntouched();
    }

    private getTimeZones() {
        if (this.timeZones.length === 0) {
            if (localStorage.getItem('timeZones')) {
                this.timeZones = JSON.parse(localStorage.getItem('timeZones'));
            } else {
                const timeZones = this.countryManagementService.getTimeZones();
                if (timeZones) {
                    timeZones.subscribe(timeZones => {
                        localStorage.setItem('timeZones', JSON.stringify(timeZones));
                        this.timeZones = timeZones;
                    });
                }
            }
        }
    }

    private getWeekdaysWithNames() {
        return weekdays.map(item => ({
            value: item,
            name: this.translateService.get('app.countryManagement.weekdays.' + item),
        }));
    }

    private mapWorkingDaysToCountry(selectedWeekdays: any) {
        this.country.workingDayMonday = selectedWeekdays.includes(weekdays[0]);
        this.country.workingDayTuesday = selectedWeekdays.includes(weekdays[1]);
        this.country.workingDayWednesday = selectedWeekdays.includes(weekdays[2]);
        this.country.workingDayThursday = selectedWeekdays.includes(weekdays[3]);
        this.country.workingDayFriday = selectedWeekdays.includes(weekdays[4]);
        this.country.workingDaySaturday = selectedWeekdays.includes(weekdays[5]);
        this.country.workingDaySunday = selectedWeekdays.includes(weekdays[6]);
    }

    orRowUpdated(event: any) {
        const updatedRow = event.data;
        const index = this.weekdays.findIndex(item => item.weekday === updatedRow.weekday)

        if (index !== -1) {
            this.weekdays[index] = { ...this.weekdays[index], ...updatedRow };
        }

        if (this.country.id === 'BE') {
            this.country.weekdays = this.weekdays.map(item => ({
                weekday: item.weekday,
                cutOffTime: item.cutOffTime,
                regularExtraDays: item.regularExtraDays,
                weekdayMap: {
                    NL: item?.nl,
                    FR: item?.fr,
                },
                nl: item.nl,
                fr: item.fr,
            }));
        } else {
            const id = this.country.id;
            this.country.weekdays = this.weekdays.map(item => ({
                weekday: item.weekday,
                cutOffTime: item.cutOffTime,
                regularExtraDays: item.regularExtraDays,
                weekdayMap: {
                    [id]: item.displayValue,
                },
            }));
        }

        this.onSaving();
    }
}
