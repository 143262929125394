<!-- Navbar component. -->
<eci-navbar>

    <li *eciHasAnyAuthority="[ 'EDIT_BANK_HOLIDAYS', 'EDIT_COUNTRY_MANAGEMENT', 'EDIT_ZIP_CODE_DELIVERY', 'EDIT_PLANT_CALCULATION_CLUSTER' ]"
        class="nav-item dropdown pointer"
        routerLinkActive="active"
        (click)="navbar.collapseNavbar()"
        [routerLinkActiveOptions]="{exact: true}"
        ngbDropdown>
        <a class="nav-link dropdown-toggle"
           href="javascript:void(0);"
           ngbDropdownToggle>
            <i class="fas fas fa-database mr-2"
               aria-hidden="true"></i>
            <span eciTranslate="app.menu.master-data.title">Master data</span>
        </a>
        <ul class="dropdown-menu"
            ngbDropdownMenu>
            <li>
                <a class="dropdown-item"
                   routerLink="/master-data">
                    <i aria-hidden="true" class="fas fas fa-database mr-2"></i>
                    <span eciTranslate="app.menu.master-data.title"></span>
                </a>
            </li>
            <li>
                <a class="dropdown-item"
                   routerLink="/bank-holidays">
                    <i aria-hidden="true" class="fas fa-calendar-alt"></i>
                    <span eciTranslate="bank-holidays.title"></span>
                </a>
            </li>
            <li>
                <a class="dropdown-item"
                   routerLink="/zip-code-delivery">
                    <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                    <span eciTranslate="zip-code-delivery.title"></span>
                </a>
            </li>
            <li>
                <a class="dropdown-item"
                   routerLink="/plant-calculation-cluster">
                    <i aria-hidden="true" class="fa fa-cubes"></i>
                    <span eciTranslate="plant-calculation-cluster.title"></span>
                </a>
            </li>
        </ul>
    </li>

    <div (click)="navbar.collapseNavbar()"
         *ngIf="principalService.isAuthenticated()"
         class="nav-item pull-left"
         routerLinkActive="active">

        <a class="nav-link" routerLink="/calculation-log">
            <i aria-hidden="true" class="fas fa-stream"></i>
            <span eciTranslate="app.menu.calculation-log">Log</span>
        </a>

    </div>

    <div (click)="navbar.collapseNavbar()"
         *eciHasAnyAuthority="[ 'EDIT_DELIVERY_PRODUCTS' ]"
        class="nav-item pull-left"
        routerLinkActive="active">

        <a class="nav-link" routerLink="/delivery-times">
            <i aria-hidden="true" class="fas fa-truck"></i>
            <span eciTranslate="app.menu.delivery-times">Delivery-Times</span>
        </a>

    </div>

    <li *ngIf="principalService.isAuthenticated()"
        class="nav-item dropdown pointer"
        routerLinkActive="active"
        (click)="navbar.collapseNavbar()"
        [routerLinkActiveOptions]="{exact: true}"
        ngbDropdown>
        <a class="nav-link dropdown-toggle"
           href="javascript:void(0);"
           ngbDropdownToggle>
            <i class="fas fa-history"
               aria-hidden="true"></i>
            <span eciTranslate="app.menu.history">History</span>
        </a>
        <ul class="dropdown-menu"
            ngbDropdownMenu>
            <li>
                <a class="dropdown-item"
                   routerLink="/change-history-log">
                    <i aria-hidden="true" class="fas fa-history"></i>
                    <span eciTranslate="app.menu.change-history-log">Change-History-Log</span>
                </a>
            </li>
            <li>
                <a class="dropdown-item"
                   routerLink="/article-history">
                    <i aria-hidden="true" class="fas fa-history"></i>
                    <span eciTranslate="app.menu.article-history"></span>
                </a>
            </li>
        </ul>
    </li>
        <li *ngIf="principalService.isAuthenticated()"
            class="nav-item dropdown pointer"
            routerLinkActive="active"
            (click)="navbar.collapseNavbar()"
            [routerLinkActiveOptions]="{exact: true}"
            ngbDropdown>
            <a class="nav-link dropdown-toggle"
               href="javascript:void(0);"
               ngbDropdownToggle>
                <i class="fas fa-play-circle"
                   aria-hidden="true"></i>
                <span eciTranslate="app.menu.jobs">Jobs</span>
            </a>
            <ul class="dropdown-menu"
                ngbDropdownMenu>
                <li *eciHasAnyAuthority="[ 'EDIT_VLT_JOBS' ]">
                    <a class="dropdown-item"
                       routerLink="/vlt-job">
                        <i aria-hidden="true" class="fas fa-cube"></i>
                        <span eciTranslate="app.menu.vlt-job"></span>
                    </a>
                </li>
                <li  *eciHasAnyAuthority="[ 'EDIT_MASTER_ARTICLE_JOBS' ]">
                    <a class="dropdown-item"
                       routerLink="/master-article-job">
                        <i aria-hidden="true" class="fas fa-cubes"></i>
                        <span eciTranslate="app.menu.master-article-job"></span>
                    </a>
                </li>
            </ul>
        </li>

</eci-navbar>

<ng-container *ngIf="principalService.isAuthenticated() && isHomepage === false">
    <eci-news-alert></eci-news-alert>
</ng-container>

<!-- Main router outlet area. Basic viewport for all routed components. -->
<div class="container-fluid">
    <div>
        <router-outlet></router-outlet>
    </div>
</div>

<!-- Footer component. -->
<eci-footer>

</eci-footer>
