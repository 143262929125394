<div class="page">

    <div class="page__headline">
        <span eciTranslate="app.changeHistory.title">Change History Log</span>
        <span eciTranslate="global.forCountry" translateValues="{country: '{{ country }}'}">for {{country}}</span>
    </div>

    <div class="page__grid mb-4">

        <dx-data-grid [allowColumnReordering]="true"
                      [allowColumnResizing]="true"
                      [dataSource]="dataSource"
                      [masterDetail]="{ enabled: false, template: 'detail' }"
                      [remoteOperations]="{ paging: true, sorting: true, filtering: false }"
                      [showBorders]="true"
                      [showColumnLines]="true"
                      [showRowLines]="true"
                      [wordWrapEnabled]="true"
                      class="h-100">
            <dxo-paging [pageSize]="20"></dxo-paging>
            <dxo-pager
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[10, 20, 50, 100]"
                [showInfo]="true">
            </dxo-pager>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>

            <dxi-column [allowEditing]="false"
                        sortOrder="desc"
                        [caption]="'app.changeHistory.columns.date' | translate"
                        dataField="date"
                        dataType="date"
                        format="shortDate">
            </dxi-column>

            <dxi-column [allowEditing]="false"
                        sortOrder="desc"
                        [caption]="'app.changeHistory.columns.time' | translate"
                        dataField="date"
                        dataType="datetime"
                        format="longTime">
            </dxi-column>

            <dxi-column [allowEditing]="false"
                        [caption]="'app.changeHistory.columns.username' | translate"
                        dataField="username"
                        dataType="string">
            </dxi-column>

            <dxi-column [allowEditing]="false"
                        [caption]="'app.changeHistory.columns.changeEntity' | translate"
                        dataField="changeEntity"
                        dataType="string">
            </dxi-column>

            <dxi-column [allowEditing]="false"
                        [caption]="'app.changeHistory.columns.changeMessage' | translate"
                        dataField="changeMessage"
                        dataType="string">
            </dxi-column>



        </dx-data-grid>

    </div>

</div>
