<div class="page">

    <div class="page__headline">
        <span eciTranslate="app.calculationLog.title">Country management</span>
        <span eciTranslate="global.forCountry" translateValues="{country: '{{ country }}'}">for {{country}}</span>
    </div>

    <div class="page__grid mb-4">

        <dx-data-grid [allowColumnReordering]="true"
                      [allowColumnResizing]="true"
                      [dataSource]="dataSource"
                      [masterDetail]="{ enabled: true, template: 'detail' }"
                      [remoteOperations]="{ paging: true, sorting: true, filtering: true }"
                      [showBorders]="true"
                      [showColumnLines]="true"
                      [showRowLines]="true"
                      [wordWrapEnabled]="true"
                      (onToolbarPreparing)="onToolbarPreparing($event)"
                      class="h-100">
            <dxo-paging [pageSize]="20"></dxo-paging>
            <dxo-pager
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[10, 20, 50, 100]"
                [showInfo]="true">
            </dxo-pager>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>

            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxi-column [allowEditing]="false"
                        sortOrder="desc"
                        [caption]="'app.calculationLog.columns.requestDate' | translate"
                        dataField="requestDate"
                        dataType="datetime"
                        [calculateFilterExpression]="dataGridUtilService.calculateFilterExpression">
            </dxi-column>

            <dxi-column [allowEditing]="false"
                        [caption]="'app.calculationLog.columns.orderDate' | translate"
                        dataField="orderDate"
                        dataType="datetime"
                        [calculateFilterExpression]="dataGridUtilService.calculateFilterExpression"
                        >
            </dxi-column>


            <dxi-column [allowEditing]="false"
                        [caption]="'app.calculationLog.columns.orderId' | translate"
                        dataField="orderId"
                        dataType="string">
            </dxi-column>

            <dxi-column [allowEditing]="false"
                        [caption]="'app.calculationLog.columns.calculatedDeliveryDate' | translate"
                        dataField="calculatedDeliveryDate"
                        dataType="date">
            </dxi-column>

            <dxi-column [allowEditing]="false"
                        [caption]="'app.calculationLog.columns.articleCount' | translate"
                        dataField="articleCount"
                        dataType="number">
            </dxi-column>

            <dxi-column [allowEditing]="false"
                        [caption]="'app.calculationLog.columns.paymentMethod' | translate"
                        dataField="paymentMethod"
                        dataType="number">
            </dxi-column>

            <dxi-column [allowEditing]="false"
                        [caption]="'app.calculationLog.columns.calculationTime' | translate"
                        dataField="calculationTime"
                        dataType="number">
            </dxi-column>

            <div *dxTemplate="let log of 'detail'">
                <div class="detail_row">
                    <div class="detail_row-caption">{{  'app.calculationLog.columns.regardedHolidayDays' | translate }}:</div>
                    <div class="detail_row-data">{{ log.data.regardedHolidayDays || '-' }}</div>
                </div>
                <div class="detail_row">
                    <div class="detail_row-caption">{{  'app.calculationLog.columns.regardedWeekendDays' | translate }}:</div>
                    <div class="detail_row-data">{{ log.data.regardedWeekendDays || '-' }}</div>
                </div>
                <div class="detail_row">
                    <div class="detail_row-caption">{{  'app.calculationLog.columns.countryCutOffTime' | translate }}:</div>
                    <div class="detail_row-data">{{ log.data.countryCutOffTime }}</div>
                </div>
                <div *ngIf="log.data.comment" class="detail_row">
                    <div class="detail_row-caption">{{  'app.calculationLog.columns.comment' | translate }}:</div>
                    <div class="detail_row-data">{{ log.data.comment }}</div>
                </div>
                <div class="detail_row">
                    <div class="detail_row-caption">{{'app.calculationLog.columns.products' | translate}}:</div>
                    <dx-data-grid
                        [dataSource]="log.data.calculationLogProducts"
                        [showBorders]="true"
                        [columnAutoWidth]="true">
                        <dxi-column dataField="erpNumber" dataType="string"></dxi-column>
                        <dxi-column dataField="itemGroupCategory" dataType="string"></dxi-column>
                        <dxi-column dataField="deliveryOption" dataType="string"></dxi-column>
                        <dxi-column dataField="plantId" dataType="string"></dxi-column>
                        <dxi-column dataField="deliveryStartDate" dataType="string"></dxi-column>
                        <dxi-column dataField="daysForDelivery" dataType="string"></dxi-column>
                        <dxi-column dataField="calculatedDeliveryDate" dataType="string"></dxi-column>
                    </dx-data-grid>
                </div>
            </div>

        </dx-data-grid>

    </div>

</div>
