import { Route } from '@angular/router';

import { MasterArticleJobComponent } from './master-article-job.component';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';

export const MASTER_ARTICLE_JOB_ROUTE: Route = {
    path: 'master-article-job',
    component: MasterArticleJobComponent,
    data: {
        role: [],
        pageTitle: 'app.menu.master-article-job',
    },
    canActivate: [EciAuthGuard],
};
