import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { VltcAppModule } from './app/app.module';
import { environment } from './environments/environment';
import config from 'devextreme/core/config';
import { licenseKey } from 'src/app/devextreme-license';

config({ licenseKey })

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(VltcAppModule)
    .then(() => console.log('App is ready.'))
    .catch(err => console.error(err));
