export const downloadBlob = (blob: Blob, filename: string) => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    document.body.appendChild(a); // required for firefox
    a.click();
    setTimeout(() => {
        a.remove(); // timeout required for some browsers
    }, 100);
};
