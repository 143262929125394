import { Route } from '@angular/router';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';
import { DeliveryProductComponent } from './delivery-product.component';

export const DELIVERY_PRODUCT_ROUTE: Route = {
    path: 'delivery-times',
    component: DeliveryProductComponent,
    data: {
        role: [],
        pageTitle: 'app.menu.delivery-times',
    },
    canActivate: [EciAuthGuard],
};
