import { ChangeDetectionStrategy, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { VltJobService } from './vlt-job.service';
import { EciPrincipalService, EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { CountryService } from 'src/helpers/CountryService';
import notify from 'devextreme/ui/notify';
import { DataGridUtilService } from '../service/DataGridUtilService';

@Component({
    selector: 'vltc-vlt-jobs',
    templateUrl: './vlt-job.component.html',
    styleUrls: ['./vlt-job.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class VltJobComponent implements OnInit {

    @ViewChild(DxDataGridComponent) jobsDataGrid: DxDataGridComponent;
    @ViewChild(DxDataGridComponent) productsDataGrid: DxDataGridComponent;
    @ViewChildren(DxDataGridComponent) dataGrids: QueryList<DxDataGridComponent>;

    vltJobDataSource: DataSource;
    deliveryProductDataSource: DataSource;
    country: string;
    selectedOperation = 'contains';
    productsGridFilterValue: any = '';
    isSaveVltJobPopupVisible = false;
    jobName = '';
    gridState = '';
    deliveryTime = 0;
    priority = 1;
    type = 'CHANGE';
    jobTypes = ['CHANGE', 'RESET'];
    isUpdating = false;
    id: any;

    constructor(
        private vltJobService: VltJobService,
        public translateService: EciTenantAwareLanguageService,
        private countryService: CountryService,
        private principalService: EciPrincipalService,
        public dataGridUtilService: DataGridUtilService,
    ) {
        this.countryService.getCountry.subscribe(c => this.country = c.name);
    }

    ngOnInit() {
        this.vltJobDataSource = this.vltJobService.createVltJobDataSource();
        this.deliveryProductDataSource = this.vltJobService.createDeliveryProductDataSource();
    }

    saveNewVltJob() {
        const filterValue = this.productsGridFilterValue;
        const gridState = this.dataGrids.last.instance.state();
        const deliveryTime = this.deliveryTime;
        const vltJobName = this.jobName;
        const type = this.type;
        const priority = this.priority;

        if ((type === 'RESET' && deliveryTime !== 0) || (type === 'CHANGE' && deliveryTime <= 0)) {
            alert('Invalid delivery time. Please enter 0 for RESET or a value greater than 0 for CHANGE!');
            return;
        }

        if (priority <= 0 || priority === null){
            alert('Please choose a priority for the job!');
            return;
        }

        if (!this.isUpdating) {
            if (this.jobName === '' || filterValue === '' || this.jobName === ' ' || filterValue === ' ') {
                alert(this.translateService.get('app.deliveryProduct.search.warning'));
            } else {
                this.principalService.getIdentityAsync().then(r => {
                    this.vltJobService.saveVltJob(priority, vltJobName, filterValue, deliveryTime, gridState, r.login, this.country, type)
                        .subscribe(response => {
                            notify(this.translateService.get('app.deliveryProduct.confirmationDialog.searchSaved'), 'success', 1000);
                            this.dataGrids.first.instance.refresh();
                            this.clearGridFilter();
                        });
                    return r.login;
                }).catch(error => Promise.reject(error));
                this.isSaveVltJobPopupVisible = false;
            }
        } else {
            this.vltJobService.updateVltJob(priority, vltJobName, filterValue, deliveryTime, gridState, type, this.id).subscribe(
                response => {
                    notify(this.translateService.get('app.vltJob.filterUpdated'), 'success', 1000);
                    this.dataGrids.first.instance.refresh();
                });
            this.isSaveVltJobPopupVisible = false;
        }
    }

    cancelSaveSearch() {
        this.isSaveVltJobPopupVisible = false;
        this.jobName = '';
        this.productsGridFilterValue = '';
        this.gridState = '';
        this.priority = 0;
    }

    setProductsGridState(e) {
        console.log('Setting products grid state ' + JSON.parse(e.data.gridState));
        this.dataGrids.last.instance.state(JSON.parse(e.data.gridState));
        this.isUpdating = true;
        this.deliveryTime = e.data.deliveryTime;
        this.jobName = e.data.name;
        this.id = e.data.id;
        this.priority = e.data.priority;
    }

    clearGridFilter() {
        this.dataGrids.last.instance.clearFilter();
        this.isUpdating = false;
        this.jobName = '';
        this.deliveryTime = 0;
        this.priority = 0;
        const columns = this.dataGrids.last.instance.getVisibleColumns();
        for (const column of columns) {
            this.dataGrids.last.instance.columnOption(column.dataField, 'filterValues', null);
            this.dataGrids.last.instance.columnOption(column.dataField, 'filterValue', null);
            this.dataGrids.last.instance.columnOption(column.dataField, 'filterType', null);
            this.dataGrids.last.instance.columnOption(column.dataField, 'selectedFilterOperation', null);
        }
    }

    showNewVltJobForm() {
        this.productsGridFilterValue = this.dataGrids.last.instance.getCombinedFilter() !== 'undefined'
            ? this.dataGrids.last.instance.getCombinedFilter() : '';
        this.gridState = this.dataGrids.last.instance.state();
        this.isSaveVltJobPopupVisible = true;
    }

    applyFilter(operation, value) {
        this.selectedOperation = operation;
        this.productsGridFilterValue = value;
    }

    successNotification() {
        notify(this.translateService.get('global.success'), 'success', 600);
    }

    updatedNotification() {
        notify(this.translateService.get('app.vltJob.updated'), 'success', 600);
    }
}
