<!-- Simple skeleton for an app-specific home component. -->
<div class="page">

    <div class="page__headline" eciTranslate="home.subtitle">
        This is your page.
    </div>

    <br>

    <div class="row">

        <div class="col-md-12">

            <div [ngSwitch]="isAuthenticated()">

                <div *ngSwitchCase="true" class="alert alert-success">
                    <span *ngIf="account" eciTranslate="home.messages.logged"
                          translateValues="{username: '{{ account.login }}'}">You are logged in as user "{{ account.login }}
                        ".
                    </span>
                    <span *ngIf="country" eciTranslate="home.messages.country"
                          translateValues="{country: '{{ country }}'}"> Your selected country is "{{ country }}".</span>
                </div>
            </div>

            <div class="row">
                <div class="container">
                    <div class="col">
                        <div class="card new-orders">
                            <div class="card-header page__headline">
                                <h5 [textContent]="'home.calculationsToday' | translate">Calculations Today</h5>
                            </div>
                            <div class="card-body page__headline">
                                <h1>{{ newCalculationLogs }}</h1>
                            </div>
                            <div class="card-footer">
                                <a class="page__headline" routerLink="/calculation-log">
                                    <span [textContent]="'home.moreInfo' | translate"></span><i
                                    class="fas fa-chevron-circle-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card">
                            <div class="card-header page__headline">
                                <h5 [textContent]="'home.masterArticles' | translate">Amount of Articles in Master
                                    Article</h5>
                            </div>
                            <div class="card-body page__headline">
                                <h1>{{ masterArticleJobs }}</h1>
                            </div>
                            <div class="card-footer">
                                <a class="page__headline" routerLink="/master-article-job">
                                    <span [textContent]="'home.moreInfo' | translate"></span><i
                                    class="fas fa-chevron-circle-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card">
                            <div class="card-header page__headline">
                                <h5 [textContent]="'home.standardDeliveryTime' | translate">
                                    Actual Standard Delivery Time
                                </h5>
                            </div>
                            <div class="card-body page__headline">
                                <h1>{{ standardDeliveryDays }}</h1>
                            </div>
                            <div class="card-footer">
                                <a class="page__headline" routerLink="/master-data">
                                    <span [textContent]="'home.moreInfo' | translate"></span><i
                                    class="fas fa-chevron-circle-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="container">
                    <div class="col">
                        <div class="card card-diagram">
                            <div class="card-header page__headline">
                                <h5 [textContent]="'home.vltJobs' | translate">Vlt Jobs</h5>
                            </div>
                            <div class="card-body">
                                <div class="row" style="height: 100%">
                                    <div class="col">
                                        <dx-button [style.background-color]="'#ffe300'"
                                                   [style.color]="'#b41e0a'"
                                                   [style.border-color]="'#ebd100'"
                                                   [style.width]="'90%'"
                                                   [style.height]="'100%'"
                                                   routerLink="/vlt-job">
                                            <h2>Running: {{ running }}</h2>
                                        </dx-button>
                                    </div>
                                    <div class="col">
                                        <dx-button [style.background-color]="'#2980b9'"
                                                   [style.color]="'white'"
                                                   [style.border-color]="'grey'"
                                                   [style.width]="'90%'"
                                                   [style.height]="'100%'"
                                                   routerLink="/vlt-job">
                                            <h2>Pending: {{ pending }}</h2>
                                        </dx-button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <a class="page__headline" routerLink="/vlt-job">
                                    <span [textContent]="'home.moreInfo' | translate"></span><i
                                    class="fas fa-chevron-circle-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card card-diagram">
                            <div class="card-header page__headline">
                                <h5 [textContent]="'home.deliveryTimes' | translate">Delivery Times</h5>
                            </div>
                            <div class="card-body">
                                <div class="row" style="height: 100%">
                                    <div class="col">
                                        <dx-button [style.background-color]="'green'"
                                                   [style.color]="'white'"
                                                   [style.border-color]="'grey'"
                                                   [style.width]="'90%'"
                                                   [style.height]="'100%'"
                                                   routerLink="/delivery-times">
                                            <h2>Global: {{ global }}</h2>
                                        </dx-button>
                                    </div>
                                    <div class="col">
                                        <dx-button [style.background-color]="'#b41e0a'"
                                                   [style.color]="'white'"
                                                   [style.border-color]="'grey'"
                                                   [style.width]="'90%'"
                                                   [style.height]="'100%'"
                                                   routerLink="/delivery-times">
                                            <h2>Individual: {{ individual }}</h2>
                                        </dx-button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <a class="page__headline" routerLink="/delivery-times">
                                    <span [textContent]="'home.moreInfo' | translate"></span><i
                                    class="fas fa-chevron-circle-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <h1 [textContent]="'home.calculationLog' | translate" class="page__headline" style="text-align: center">Calculation Logs</h1>
            <dx-chart
                [dataSource]="timeSeriesArray"
                id="calculation-chart"
                palette="violet">
                <dxi-series
                    argumentField="requestDate"
                    argumentType="datetime"
                    name="Total calculation logs {{totalCalculationLogs}}"
                    type="bar"
                    valueField="requestCountPerInterval">
                </dxi-series>
                <dxo-tooltip
                    [enabled]="true">
                </dxo-tooltip>
                <dxo-argument-axis #argumentAxisSettings
                                   [tickInterval]="15"
                                   [valueMarginsEnabled]="false"
                                   aggregationInterval="hours"
                                   argumentType="datetime">
                </dxo-argument-axis>

                <dxi-value-axis
                    name="id"
                    position="left"
                    valueType="number">
                    <dxo-grid [visible]="true"></dxo-grid>
                    <dxo-title text="Number of calculation logs"></dxo-title>
                </dxi-value-axis>
            </dx-chart>
            <div class="time-series-input">
                <dx-radio-group #eventRadioGroup
                                (onValueChanged)="onValueChanged($event)"
                                [items]="timeframes"
                                [value]="selectedTimeframe"
                                id="radio-group-with-selection"
                                layout="horizontal">
                </dx-radio-group>
            </div>
            <div class="row justify-content-center">
                <dx-circular-gauge *ngIf="gaugeValue > 0" [value]="gaugeValue"
                                   id="gauge">
                    <dxo-scale
                        [endValue]="maxGaugeValue"
                        [startValue]="0"
                        [tickInterval]="50">
                    </dxo-scale>
                    <dxo-range-container palette="pastel">
                        <dxi-range [endValue]="50" [startValue]="0"></dxi-range>
                        <dxi-range [endValue]="300" [startValue]="50"></dxi-range>
                        <dxi-range [endValue]="maxGaugeValue" [startValue]="300"></dxi-range>
                    </dxo-range-container>
                    <dxo-title text="Average calculation time: {{gaugeValue | number:'1.2-2'}} ms">
                        <dxo-font [size]="19"></dxo-font>
                    </dxo-title>
                </dx-circular-gauge>
            </div>
        </div>
    </div>

</div>
