import { Route } from '@angular/router';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';

import { BankHolidaysService } from './components/bank-holidays/bank-holidays.service';
import { BankHolidaysComponent } from './components/bank-holidays/bank-holidays.component';
import { CountryManagementComponent } from './components/country-management/country-management.component';
import { CountryManagementService } from './components/country-management/country-management.service';
import { MasterDataComponent } from './master-data.component';
import { ZipCodeDeliveryComponent } from 'src/app/master-data/components/zip-code-delivery/zip-code-delivery.component';
import { ZipCodeDeliveryService } from 'src/app/master-data/components/zip-code-delivery/zip-code-delivery.service';
import { PlantCalculationClusterComponent } from './components/plant-calculation-cluster/plant-calculation-cluster.component';
import { PlantCalculationClusterService } from './components/plant-calculation-cluster/plant-calculation-cluster.service';

export const MASTER_DATA_COMPONENTS = [
    BankHolidaysComponent,
    CountryManagementComponent,
    MasterDataComponent,
    ZipCodeDeliveryComponent,
    PlantCalculationClusterComponent,
];

export const MASTER_DATA_SERVICES = [
    BankHolidaysService,
    CountryManagementService,
    ZipCodeDeliveryService,
    PlantCalculationClusterService,
];

export const MASTER_DATA_ROUTE: Route = {
    path: 'master-data',
    component: MasterDataComponent,
    data: {
        role: [],
        pageTitle: 'app.menu.master-data.title',
    },
    canActivate: [EciAuthGuard],
};

export const BANK_HOLIDAYS_ROUTE: Route = {
    path: 'bank-holidays',
    component: BankHolidaysComponent,
    data: {
        role: [],
        pageTitle: 'bank-holidays.title',
    },
    canActivate: [EciAuthGuard],
};

export const ZIP_CODE_DELIVERY_ROUTE: Route = {
    path: 'zip-code-delivery',
    component: ZipCodeDeliveryComponent,
    data: {
        role: [],
        pageTitle: 'zip-code-delivery.title',
    },
    canActivate: [EciAuthGuard],
};
export const PLANT_CALCULATION_CLUSTER_ROUTE: Route = {
    path: 'plant-calculation-cluster',
    component: PlantCalculationClusterComponent,
    data: {
        role: [],
        pageTitle: 'plant-calculation-cluster.title',
    },
    canActivate: [EciAuthGuard],
};
