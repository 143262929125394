import { Route } from '@angular/router';

import { ArticleHistoryComponent } from './article-history.component';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';

export const ARTICLE_HISTORY_ROUTE: Route = {
    path: 'article-history',
    component: ArticleHistoryComponent,
    data: {
        role: [],
        pageTitle: 'app.menu.article-history',
    },
    canActivate: [EciAuthGuard],
};
