import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export class TimeSeries {
    requestDate: Date;
    requestCountPerInterval: number;
}

export interface TimeSeriesResponse {
    averageCalculationTime: number;
    maxCalculationTime: number;
    minCalculationTime: number;
    totalCalculationLogs: number;
    timeSeries: TimeSeries[];
}

@Injectable({
    providedIn: 'root',
})

export class HomeService {

    constructor(
        private httpClient: HttpClient,
    ) {
    }

    getTimeSeries(countryId: string, selectedTimeframe: string): Observable<TimeSeriesResponse> {
        return this.httpClient.get<TimeSeriesResponse>('api/calculation-logs/time-series?timeSeries=' + selectedTimeframe + '&country=' + countryId, {
            responseType: 'json',
        });
    }

    getNewCalculationLogs(countryId: string) {
        return this.httpClient.get<number>('api/calculation-logs/new-logs?country='
            + countryId, {
            responseType: 'json',
        });
    }

    getVltJobs(countryId: string) {
        return this.httpClient.get<any>('api/vlt-jobs?country='
            + countryId, {
            responseType: 'json',
        });
    }

    getMasterArticleJobs(countryId: string) {
        return this.httpClient.get<any>('api/master-article-jobs?country='
            + countryId, {
            responseType: 'json',
        });
    }

    getNumberOfMaintained(countryId: string, maintained: boolean) {
        const params = new HttpParams()
            .set('country', countryId)
            .set('maintained', maintained);

        return this.httpClient.get<number>('/api/delivery-products/maintained', {
            params,
            responseType: 'json',
        });
    }
}
