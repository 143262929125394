import { Injectable } from '@angular/core';
import { Country } from './Country';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import { EciDxService } from '@shared-components/shopsys-commons-ui';

@Injectable()
export class CountryManagementService {

    constructor(
        private httpClient: HttpClient,
        private dxService: EciDxService,
    ) {
    }

    getCountry(): Observable<Country> {
        return this.httpClient.get<Country>('api/countries', {
            responseType: 'json',
        }).pipe(map(countries => countries[0]));
    }

    updateCountry(country: Country) {
        return this.httpClient.put('api/countries/' + country.id, country, {
            responseType: 'json',
        }).toPromise();
    }

    getTimeZones(): Observable<string[]> {
        return this.httpClient.get<string[]>('api/countries/time-zones', {
            responseType: 'json',
        });
    }

    getPlantMinDeliveryTimesForCountry(): DataSource {
        return this.dxService.createEditableDataSource(
            'api/plant-min-delivery-time',
            'id',
        );
    }
}
