import { Route } from '@angular/router';

import { HomeComponent } from './home.component';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';

export const HOME_ROUTE: Route = {
  path: '',
  component: HomeComponent,
  data: {
    roles: [],
    pageTitle: 'home.title',
  },
    canActivate: [EciAuthGuard],
};
