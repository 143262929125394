import main from './de-DE/main.json';
import home from './de-DE/home.json';
import country_management from './de-DE/country-management.json';
import bank_holiday from './de-DE/bank-holidays.json';
import calculation_log from './de-DE/calculation-log.json';
import delivery_product from './de-DE/delivery-product.json';
import change_history_log from './de-DE/change-history-log.json';
import vlt_job from './de-DE/vlt-jobs.json';
import master_article_job from './de-DE/master-article-job.json';
import zip_code_range from './de-DE/zip-code-delivery.json';
import article_history from './de-DE/article-history.json';
import plant_calculation_cluster from './de-DE/plant-calculation-cluster.json';

export const de_DE = [main, home, country_management, bank_holiday, calculation_log, delivery_product, change_history_log, vlt_job, master_article_job
, zip_code_range, article_history, plant_calculation_cluster];
