import main from './en-GB/main.json';
import home from './en-GB/home.json';
import country_management from './en-GB/country-management.json';
import bank_holiday from './en-GB/bank-holidays.json';
import calculation_log from './en-GB/calculation-log.json';
import delivery_product from './en-GB/delivery-product.json';
import change_history_log from './en-GB/change-history-log.json';
import vlt_job from './en-GB/vlt-jobs.json';
import master_article_job from './en-GB/master-article-job.json';
import zip_code_range from './en-GB/zip-code-delivery.json';
import article_history from './en-GB/article-history.json';
import plant_calculation_cluster from './en-GB/plant-calculation-cluster.json';

export const en_GB = [main, home, country_management, bank_holiday, calculation_log, delivery_product, change_history_log, vlt_job, master_article_job,
    zip_code_range, article_history, plant_calculation_cluster];
