import { Component } from '@angular/core';
import { CountryService } from 'src/helpers/CountryService';

@Component({
    selector: 'vltc-master-data',
    templateUrl: './master-data.component.html',
})
export class MasterDataComponent {
    country: string;

    constructor(
        private countryService: CountryService,
    ) {
        this.countryService.getCountry.subscribe(c => this.country = c.name);
    }

}
