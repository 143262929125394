<div class="page">

    <div class="page__headline">
        <span eciTranslate="app.vltJob.title">VLT Jobs</span>
        <span eciTranslate="global.forCountry" translateValues="{country: '{{ country }}'}">for {{country}}</span>
    </div>

    <div class="page__grid mb-4">
        <dx-data-grid id="jobsGridContainer" #jobsGridContainer
                      [dataSource]="vltJobDataSource"
                      [remoteOperations]="true"
                      [allowColumnReordering]="true"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [showBorders]="true"
                      [showColumnLines]="true"
                      [showRowLines]="true"
                      [wordWrapEnabled]="true"
                      (onRowClick)="setProductsGridState($event)"
                      (onRowInserted)="successNotification()"
                      (onRowUpdated)="updatedNotification()"
                      class="h-50" style="height: 100px;width: fit-content">
            <dxo-editing
                [allowAdding]="false"
                [allowDeleting]="true"
                [allowUpdating]="true"
                [useIcons]="true"
                mode="popup">
                <dxo-popup
                    [height]="525"
                    [showTitle]="true"
                    [width]="1200"
                    [title] ="'app.vltJob.editTitle' | translate">
                </dxo-popup>
            </dxo-editing>
            <dxi-column
                [caption]="'app.vltJob.columns.priority' | translate"
                [minWidth]="150"
                dataField="priority"
                dataType="number"
                sortOrder="asc">
            </dxi-column>
            <dxi-column
                [caption]="'app.vltJob.columns.name' | translate"
                [minWidth]="150"
                dataField="name"
                dataType="string">
            </dxi-column>
            <dxi-column
                [caption]="'app.vltJob.columns.filterValue' | translate"
                [minWidth]="250"
                [allowEditing]="false"
                dataField="filterValue"
                dataType="string">
            </dxi-column>
            <dxi-column
                [caption]="'app.vltJob.columns.deliveryTime' | translate"
                [minWidth]="250"
                dataField="deliveryTime"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.vltJob.columns.type' | translate"
                [minWidth]="120"
                dataField="type"
                dataType="string">
                <dxo-lookup [dataSource]="jobTypes"></dxo-lookup>
            </dxi-column>
            <dxi-column
                [caption]="'app.vltJob.columns.enabled' | translate"
                [minWidth]="120"
                dataField="enabled"
                dataType="boolean">
            </dxi-column>
            <dxi-column
                [caption]="'app.vltJob.columns.createdAt' | translate"
                [minWidth]="120"
                dataField="createdAt"
                dataType="datetime">
            </dxi-column>
            <dxi-column
                [caption]="'app.vltJob.columns.updatedAt' | translate"
                [minWidth]="120"
                dataField="updatedAt"
                dataType="datetime">
            </dxi-column>
            <dxi-column type="buttons" width="100">
                <dxi-button name="edit"></dxi-button>
                <dxi-button name="delete"></dxi-button>
            </dxi-column>
        </dx-data-grid>
    </div>
    <dx-popup
        id="saveJobPopup"
        [(visible)]="isSaveVltJobPopupVisible"
        [title]="this.translateService.get('app.vltJob.title')">
        <dx-form id="searchForm" class="file-uploader-form">

            <dxi-item dataField="priority" >
                <dxo-label [text] ="this.translateService.get('app.vltJob.priority')"></dxo-label>
                <dx-number-box  [(value)]="priority">
                </dx-number-box>
                <dxi-validation-rule
                    type="required"
                    message="Priority is required">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item>
                <dxo-label [text] ="this.translateService.get('app.vltJob.jobName')"></dxo-label>
                <dx-text-box  [(value)]="jobName">
                </dx-text-box>
                <dxi-validation-rule
                    type="required"
                    message="VLT Job name is required">
                </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="productsGridFilterValue" >
                <dxo-label [text] ="this.translateService.get('app.vltJob.filterValue')"></dxo-label>
                <dx-text-box [(value)]="productsGridFilterValue">
                </dx-text-box>
            </dxi-item>
            <dxi-item dataField="deliveryTime" >
                <dxo-label [text] ="this.translateService.get('app.vltJob.deliveryTime')"></dxo-label>
                <dx-number-box  [(value)]="deliveryTime">
                </dx-number-box>
            </dxi-item>
            <dxi-item dataField="type" >
                <dxo-label [text] ="this.translateService.get('app.vltJob.type')"></dxo-label>
                <dx-radio-group
                    [dataSource]="jobTypes"
                    [(value)]="type">
                </dx-radio-group>
            </dxi-item>


            <dxi-item>
                <dx-button (onClick)="saveNewVltJob()"
                           [text]="this.translateService.get('app.deliveryProduct.search.save')"
                           type="success" stylingMode="outlined" useSubmitBehavior="true"></dx-button>
            </dxi-item>
            <input type="hidden" name="gridState" [(ngModel)]="gridState">
            <dx-button  (onClick)="cancelSaveSearch()"  text="Clear search" type="success" stylingMode="outlined"
                        useSubmitBehavior="false"></dx-button>
        </dx-form>
    </dx-popup>

    <div class="page__grid mb-4">
        <div id="searchPanel"  class="search-panel" >
            <div id="search-file-uploader" >
                <div id="buttons-container">
                    <div id="search-buttons-container">
                        <dx-button id="saveButton" class="search-save" (onClick)="showNewVltJobForm()"  text="" type="success"  icon="save" stylingMode="outlined"  useSubmitBehavior="false"></dx-button>
                        <dx-button id="clearButton" class="search-clear" (onClick)="clearGridFilter()"  text="" type="success"   icon="clear" stylingMode="outlined" useSubmitBehavior="false"></dx-button>
                    </div>
                </div>
            </div>

        </div>
        <dx-data-grid id="productsGridContainer" #productsGridContainer
                      [allowColumnReordering]="true"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [dataSource]="deliveryProductDataSource"
                      [remoteOperations]="true"
                      [showBorders]="true"
                      [showColumnLines]="true"
                      [showRowLines]="true"
                      [wordWrapEnabled]="true"
                      [keyExpr]="'erpNumber'"
                      class="h-100">
            <dxo-paging [pageSize]="20"></dxo-paging>
            <dxo-state-storing
                [enabled]="true"
                type="custom">
            </dxo-state-storing>
            <dxo-pager
                [allowedPageSizes]="[10, 20, 50, 100]"
                [showInfo]="true"
                [showPageSizeSelector]="true">
            </dxo-pager>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.erpNumber' | translate"
                [minWidth]="150"
                dataField="erpNumber"
                dataType="string"
                sortOrder="desc">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.name' | translate"
                [minWidth]="250"
                dataField="name"
                dataType="string">
            </dxi-column>


            <dxi-column
                [caption]="'app.deliveryProduct.columns.daysForDelivery' | translate"
                [minWidth]="120"
                dataField="daysForDelivery"
                dataType="number">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.deliveryTimeMaintained' | translate"
                [minWidth]="150"
                [trueText]="'Individual'"
                [falseText]="'Global'"
                dataField="deliveryTimeMaintained"
                dataType="boolean"
                cellTemplate="cellTemplate">
            </dxi-column>

            <div *dxTemplate="let data of 'cellTemplate'">
                <dx-button [style.background-color]=" data.value ? 'red' : 'green'"
                           [style.color]="'white'"
                           [style.border-color]="'grey'"
                           [style.width]="'75%'"
                           [style.height]="'40px'">
                    {{data.value ? 'Individual' : 'Global'}}
                </dx-button>
            </div>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.inMasterArticle' | translate"
                [minWidth]="150"
                dataField="inMasterArticle"
                dataType="boolean">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.itemGroupCategory' | translate"
                [minWidth]="120"
                dataField="itemGroupCategory"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.deliveryStartDate' | translate"
                [minWidth]="150"
                dataField="deliveryStartDate"
                dataType="date">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.isBulky' | translate"
                [minWidth]="150"
                dataField="isBulky"
                dataType="boolean">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockLud' | translate"
                [minWidth]="150"
                dataField="realStockLud"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockPil' | translate"
                [minWidth]="150"
                dataField="realStockPil"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockHue' | translate"
                [minWidth]="150"
                dataField="realStockHue"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockBlo' | translate"
                [minWidth]="150"
                dataField="realStockBlo"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockRot' | translate"
                [minWidth]="150"
                dataField="realStockRot"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockSos' | translate"
                [minWidth]="150"
                dataField="realStockSos"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockRoo' | translate"
                [minWidth]="150"
                dataField="realStockRoo"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockVen' | translate"
                [minWidth]="150"
                dataField="realStockVen"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockEcht' | translate"
                [minWidth]="150"
                dataField="realStockP3Echt"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.articleStatus' | translate"
                [minWidth]="120"
                dataField="articleStatus"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.grossWeightValue' | translate"
                [minWidth]="120"
                dataField="grossWeightValue"
                dataType="number">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.grossWeightUnit' | translate"
                [minWidth]="120"
                dataField="grossWeightUnit"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.heightValue'  | translate"
                [minWidth]="120"
                dataField="heightValue"
                dataType="number">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.heightUnit' | translate"
                [minWidth]="120"
                dataField="heightUnit"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.widthValue' | translate"
                [minWidth]="120"
                dataField="widthValue"
                dataType="number">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.widthUnit' | translate"
                [minWidth]="120"
                dataField="widthUnit"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.lengthValue'  | translate"
                [minWidth]="120"
                dataField="lengthValue"
                dataType="number">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.lengthUnit' | translate"
                [minWidth]="120"
                dataField="lengthUnit"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.girthValue' | translate"
                [minWidth]="120"
                dataField="girthValue"
                dataType="number">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.materialGroup' | translate"
                [minWidth]="120"
                dataField="materialGroup"
                dataType="string">
            </dxi-column>

            <dxi-column
                [calculateFilterExpression]="dataGridUtilService.calculateFilterExpression"
                [caption]="'app.deliveryProduct.columns.updatedAt' | translate"
                [minWidth]="150"
                dataField="updatedAt"
                dataType="datetime">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.comment' | translate"
                [minWidth]="250"
                dataField="comment"
                dataType="string">
            </dxi-column>
        </dx-data-grid>
    </div>

</div>
