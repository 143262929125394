import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DataGridUtilService {

    calculateFilterExpression(filterValue: Date, selectedFilterOperation: string) {
        const column = this as any;
        let filterExpression: any;
        let start: Date;
        let end: Date;

        if (Array.isArray(filterValue)) {
            if (filterValue[0] != null) {
                const date = filterValue[0];
                start = this.setDate(date);
            }
            if (filterValue[1] != null) {
                const date = filterValue[1];
                end = this.setDate(date);
            }
        } else {
            const date = filterValue;
            start = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        }
        if (selectedFilterOperation === 'between') {
            if (Array.isArray(filterValue)) {
                if (filterValue[0] != null && filterValue[1] == null) {
                    filterExpression = column.dataField + '> ' + start.toISOString();
                } else if (filterValue[0] == null && filterValue[1] != null) {
                    filterExpression = [column.dataField, '<', end.toISOString()];
                } else {
                    filterExpression = [
                        [column.dataField, '>', start.toISOString()],
                        'and',
                        [column.dataField, '<', end.toISOString()],
                    ].toString();
                }
            } else {
                filterExpression = [column.dataField, selectedFilterOperation, start.toISOString()];
            }
            return filterExpression;
        }
        if (selectedFilterOperation === '<>') {
            const endTime = new Date(start.getTime());
            endTime.setMinutes(endTime.getMinutes() + 1);
            filterExpression = [
                [column.dataField, '<', start.toISOString()],
                'or',
                [column.dataField, '>=', endTime.toISOString()],
            ];
            return filterExpression;
        }

        if (selectedFilterOperation === '=') {
            const endTime = new Date(start.getTime());
            endTime.setMinutes(endTime.getMinutes() + 1);
            filterExpression = [
                [column.dataField, '<', endTime.toISOString()],
                'and',
                [column.dataField, '>=', start.toISOString()],
            ];
            return filterExpression;
        }

        if (selectedFilterOperation === '>' || selectedFilterOperation === '<'
            || selectedFilterOperation === '>=' || selectedFilterOperation === '<=') {
            filterExpression = [column.dataField, selectedFilterOperation, start.toISOString()];
            return filterExpression;
        }
        return column.defaultCalculateFilterExpression.apply(column, arguments);
    }

    setDate(date: Date) {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    }
}
