import { Route } from '@angular/router';
import { ChangeHistoryComponent } from 'src/app/change-history-log/change-history-log.component';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';

export const CHANGE_HISTORY_LOG_ROUTE: Route = {
    path: 'change-history-log',
    component: ChangeHistoryComponent,
    data: {
        role: [],
        pageTitle: 'app.menu.change-history-log',
    },
    canActivate: [EciAuthGuard],
};
