<div class="page__headline">
    <span eciTranslate="bank-holidays.title">Bank Holidays</span>
</div>

<div class="page__container bank-holidays_container">
    <eci-alert-error></eci-alert-error>
    <dx-data-grid
        (onEditorPrepared)="onEditorPreparing($event)"
        (onInitNewRow)="onNewEntry($event)"
        (onRowInserted)="successNotification()"
        (onRowUpdated)="successNotification()"
        (onToolbarPreparing)="onToolbarPreparing($event)"
        [allowColumnReordering]="true"
        [allowColumnResizing]="true"
        [cellHintEnabled]="true"
        [columnAutoWidth]="true"
        [dataSource]="dataSource"
        [hoverStateEnabled]="true"
        [remoteOperations]="true"
        [showBorders]="true"
        [showColumnLines]="true"
        [showRowLines]="true"
        [wordWrapEnabled]="true">
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-pager [showInfo]="true"></dxo-pager>
        <dxo-selection
            [selectAllMode]="'allMode'"
            [showCheckBoxesMode]="'always'"
            mode="multiple">
        </dxo-selection>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>

        <dxo-editing
            [allowAdding]="true"
            [allowDeleting]="true"
            [allowUpdating]="true"
            [useIcons]="true"
            mode="form">
            <dxo-form
                [colCount]="1"
                [elementAttr]="{ class: 'bank-holidays_edit' }">
                <dxi-item
                    cssClass="bank-holidays_edit_item"
                    dataField="name"
                    dataType="string">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxi-validation-rule
                        [max]="100"
                        type="stringLength"
                    ></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="date" dataType="date">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item
                    dataField="active"
                    dataType="boolean"
                    editorType="dxSwitch">
                </dxi-item>
            </dxo-form>
        </dxo-editing>

        <dxi-column
            [caption]="'bank-holidays.properties.name' | translate"
            alignment="left"
            dataField="name"
            dataType="string">
        </dxi-column>

        <dxi-column
            [caption]="'bank-holidays.properties.date' | translate"
            alignment="left"
            dataField="date"
            dataType="date">
        </dxi-column>

        <dxi-column
            [caption]="'bank-holidays.properties.active' | translate"
            cssClass="active-checkbox"
            dataField="active"
            dataType="boolean">
        </dxi-column>
    </dx-data-grid>
</div>
