import { Injectable } from '@angular/core';

import DataSource from 'devextreme/data/data_source';

import { EciDxService } from '@shared-components/shopsys-commons-ui';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BankHolidaysService {
    constructor(private http: HttpClient, private dxService: EciDxService) {}

    createDataSource(): DataSource {
        return this.dxService.createEditableDataSource(
            'api/bank-holidays',
            'id',
        );
    }

    cloneBankHolidaysToNextYear(bankHolidayIds: number[]): Observable<object> {
        return this.http.post('api/bank-holidays/next-year', bankHolidayIds, {
            observe: 'response',
        });
    }
}
