import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule, TRANSLATIONS } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    activationRoute,
    adminState,
    ECI_DEFAULT_ERROR_HANDLERS,
    ECI_ERROR_HANDLER_FACTORY,
    ECI_ERROR_HANDLER_TOKEN,
    errorRoute,
    loginRoute,
    ModuleConfig,
    passwordResetRoute,
    settingsRoute,
    SHOPSYS_COMMONS_UI_TRANSLATIONS,
    ShopsysCommonsModule,
    tenantAwareTranslateLoader,
    tenantSwitchRoute
} from '@shared-components/shopsys-commons-ui';
import { VltcMainComponent } from './main/main.component';
import { _VERSION } from './build.constants';
import { HOME_ROUTE, HomeComponent, HomeService } from './home';
import {
    DevExtremeModule,
    DxButtonModule,
    DxChartModule,
    DxCheckBoxModule,
    DxCircularGaugeModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxFormModule,
    DxLoadPanelModule,
    DxNumberBoxModule,
    DxRadioGroupModule,
    DxSelectBoxModule,
    DxSliderModule,
    DxTagBoxModule,
    DxTemplateModule,
    DxTextBoxModule,
    DxValidationSummaryModule,
    DxValidatorModule
} from 'devextreme-angular';
import { VLTC_TRANSLATIONS } from 'src/i18n/translations';
import {
    BANK_HOLIDAYS_ROUTE,
    MASTER_DATA_COMPONENTS,
    MASTER_DATA_ROUTE,
    MASTER_DATA_SERVICES,
    PLANT_CALCULATION_CLUSTER_ROUTE,
    ZIP_CODE_DELIVERY_ROUTE
} from './master-data';
import { CALCULATION_LOG_ROUTE, CalculationLogComponent, CalculationLogService } from './calculation-log';
import { CHANGE_HISTORY_LOG_ROUTE, ChangeHistoryComponent, ChangeHistoryService } from './change-history-log';
import { VLT_JOB_ROUTE, VltJobComponent, VltJobService } from './vlt-job';
import { MASTER_ARTICLE_JOB_ROUTE, MasterArticleJobComponent, MasterArticleJobService } from './master-article-job';
import { DELIVERY_PRODUCT_ROUTE, DeliveryProductComponent, DeliveryProductService } from './delivery-product';
import { ReactiveFormsModule } from '@angular/forms';
import { CountryService } from 'src/helpers/CountryService';
import { MaintenanceModeInterceptor } from 'src/app/interceptors/maintenance-mode.interceptor';
import { DataGridUtilService } from './service/DataGridUtilService';
import { ARTICLE_HISTORY_ROUTE, ArticleHistoryComponent, ArticleHistoryService } from './article-history';

const ACCOUNT_ROUTES = [
    settingsRoute,
    loginRoute,
    activationRoute,
    passwordResetRoute,
];

const defaultRoute: Route = { path: '**', redirectTo: '/', pathMatch: 'full' };

export const routes: Routes = [
    HOME_ROUTE,
    CALCULATION_LOG_ROUTE,
    DELIVERY_PRODUCT_ROUTE,
    MASTER_DATA_ROUTE,
    CHANGE_HISTORY_LOG_ROUTE,
    VLT_JOB_ROUTE,
    MASTER_ARTICLE_JOB_ROUTE,
    BANK_HOLIDAYS_ROUTE,
    ZIP_CODE_DELIVERY_ROUTE,
    PLANT_CALCULATION_CLUSTER_ROUTE,
    ARTICLE_HISTORY_ROUTE,
    ...errorRoute,
    ...adminState,
    ...tenantSwitchRoute,
    {
        path: '',
        children: ACCOUNT_ROUTES,
    },
    defaultRoute,
];

// Register locale data for 'de'
registerLocaleData(localeDe, 'de-DE');

/**
 * Root module of this application.
 *
 * The basic infrastructure is provided by importing 'ShopsysCommonsModule'.
 */
@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        BrowserModule,
        NgbModule,
        // App-specific configuration can be provided here
        ShopsysCommonsModule.forRoot({
            version: _VERSION,
            defaultI18nLang: 'de-DE',
            availableLanguages: ['de-DE', 'en-GB'],
            storagePrefix: 'vltc',
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: tenantAwareTranslateLoader,
                deps: [HttpClient, ModuleConfig],
            },
        }),
        ReactiveFormsModule,
        DxDataGridModule,
        DxTemplateModule,
        DxFormModule,
        DxCheckBoxModule,
        DxNumberBoxModule,
        DxTextBoxModule,
        DxSelectBoxModule,
        DxTagBoxModule,
        DxDateBoxModule,
        DxValidationSummaryModule,
        DxButtonModule,
        DxValidatorModule,
        DxLoadPanelModule,
        DxChartModule,
        DxRadioGroupModule,
        DxSliderModule,
        DxCircularGaugeModule,
        DevExtremeModule,
    ],
    declarations: [...MASTER_DATA_COMPONENTS, VltcMainComponent, HomeComponent, CalculationLogComponent, DeliveryProductComponent,
        ChangeHistoryComponent, VltJobComponent, MasterArticleJobComponent, ArticleHistoryComponent],
    bootstrap: [VltcMainComponent],
    providers: [
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: TRANSLATIONS, useValue: [SHOPSYS_COMMONS_UI_TRANSLATIONS, VLTC_TRANSLATIONS] },
        { provide: ECI_ERROR_HANDLER_TOKEN, useFactory: ECI_ERROR_HANDLER_FACTORY, deps: ECI_DEFAULT_ERROR_HANDLERS },
        { provide: HTTP_INTERCEPTORS, useClass: MaintenanceModeInterceptor, multi: true },
        ...MASTER_DATA_SERVICES,
        CalculationLogService,
        DeliveryProductService,
        CountryService,
        ChangeHistoryService,
        HomeService,
        VltJobService,
        MasterArticleJobService,
        DataGridUtilService,
        ArticleHistoryService,
    ],
})

export class VltcAppModule {
}
