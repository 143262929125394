import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { ChangeHistoryService } from './change-history-log.service';
import { EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { CountryService } from 'src/helpers/CountryService';

@Component({
    selector: 'vltc-change-history-log',
    templateUrl: './change-history-log.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ChangeHistoryComponent implements OnInit {

    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    dataSource: DataSource;
    country: string;
    constructor(
        private changeHistoryLogService: ChangeHistoryService,
        public translateService: EciTenantAwareLanguageService,
        private countryService: CountryService,
    ) {
        this.countryService.getCountry.subscribe(c => this.country = c.name);
    }

    ngOnInit() {
        this.dataSource = this.changeHistoryLogService.createChangeHistoryDataSource();
    }

}
