<div class="page">

    <div class="page__headline">
        <span eciTranslate="app.article-history.title">Article history</span>
        <span eciTranslate="global.forCountry" translateValues="{country: '{{ country }}'}">for {{ country }}</span>
    </div>

    <div class="page__grid mb-4">

        <dx-data-grid [allowColumnReordering]="true"
                      [allowColumnResizing]="true"
                      [dataSource]="dataSource"
                      [masterDetail]="{ enabled: true, template: 'detail' }"
                      [remoteOperations]="{ paging: true, sorting: true, filtering: true }"
                      [showBorders]="true"
                      [showColumnLines]="true"
                      [showRowLines]="true"
                      [wordWrapEnabled]="true"
                      class="h-100">
            <dxo-paging [pageSize]="20"></dxo-paging>
            <dxo-pager
                [allowedPageSizes]="[10, 20, 50, 100]"
                [showInfo]="true"
                [visible]="true"
                [showPageSizeSelector]="true"
                [showNavigationButtons]="true">
            </dxo-pager>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>

            <dxi-column [allowEditing]="false"
                        [caption]="'app.article-history.columns.articleNumber' | translate"
                        dataField="erpNumber"
                        dataType="string">
            </dxi-column>

            <dxi-column [allowEditing]="false"
                        [caption]="'app.article-history.columns.vltJobCounter' | translate"
                        [allowSorting]="true"
                        [allowFiltering]="false"
                        [allowSearch]="false"
                        [alignment]="'left'"
                        dataField="vltJobCounter"
                        dataType="number">
            </dxi-column>

            <dxi-column [allowEditing]="false"
                        [caption]="'app.article-history.updatedAt' | translate"
                        [allowSorting]="true"
                        dataField="counterLastUpdatedAt"
                        dataType="datetime"
                        sortOrder="desc"
                        [visible]="false">
            </dxi-column>

            <div *dxTemplate="let history of 'detail'">
                <div class="detail_row">
                    <div class="detail_row-caption">{{ 'app.article-history.updateHistory' | translate }}:</div>
                    <dx-data-grid
                        [dataSource]="history.data.articleHistoryDetails"
                        [showBorders]="true"
                        [columnAutoWidth]="true">
                        <dxi-column [allowEditing]="false"
                                    sortOrder="desc"
                                    [caption]="'app.article-history.columns.date' | translate"
                                    dataField="createdAt"
                                    dataType="date">
                        </dxi-column>
                        <dxi-column [allowEditing]="false"
                                    [caption]="'app.article-history.columns.time' | translate"
                                    dataField="createdAt"
                                    dataType="datetime"
                                    format="longTime">
                        </dxi-column>
                        <dxi-column [width]="200" dataField="deliveryTime" dataType="number"></dxi-column>
                        <dxi-column dataField="updatedBy" dataType="string"></dxi-column>
                    </dx-data-grid>
                </div>
            </div>

        </dx-data-grid>

    </div>

</div>
