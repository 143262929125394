import { Route } from '@angular/router';

import { CalculationLogComponent } from './calculation-log.component';
import { EciAuthGuard } from '@shared-components/shopsys-commons-ui';

export const CALCULATION_LOG_ROUTE: Route = {
    path: 'calculation-log',
    component: CalculationLogComponent,
    data: {
        role: [],
        pageTitle: 'app.menu.calculation-log',
    },
    canActivate: [EciAuthGuard],
};
