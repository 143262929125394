import { Component, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { BankHolidaysService } from './bank-holidays.service';
import notify from 'devextreme/ui/notify';
import { CountryService } from 'src/helpers/CountryService';

@Component({
    selector: 'vltc-bank-holidays',
    templateUrl: './bank-holidays.component.html',
})
export class BankHolidaysComponent {
    @ViewChild(DxDataGridComponent)
    dataGrid: DxDataGridComponent;

    dataSource: DataSource;
    country: string;
    warehouses = ['PIL', 'LUD', 'ROT', 'HUE', 'BLO', 'SOS', 'ROO', 'PIN', 'SES', 'VEN', 'ECHT'];

    constructor(
        private readonly bankHolidaysService: BankHolidaysService,
        public translateService: EciTenantAwareLanguageService,
        private readonly countryService: CountryService,
    ) {
        this.dataSource = this.bankHolidaysService.createDataSource();
        this.countryService.getCountry.subscribe(c => this.country = c.id);
    }

    successNotification() {
        notify(this.translateService.get('global.success'), 'success', 600);
    }

    onNewEntry(event) {
        event.data.active = true;
    }

    onEditorPreparing(event) {
        if (
            event.parentType === 'filterRow' &&
            event.editorName === 'dxSelectBox' &&
            event.dataField === 'active'
        ) {
            event.trueText = this.translateService.get('global.booleanAsYes');
            event.falseText = this.translateService.get('global.booleanAsNo');
        }
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'fa fa-copy',
                // If someone finds an icon that describes the "copy to next year"
                // functionality better we could remove the following text.
                text: this.translateService.get(
                    'bank-holidays.toolbar.copy-to-next-year.button-text',
                ),
                onClick: this.cloneBankHolidaysToNextYear.bind(this),
            },
        });
    }

    cloneBankHolidaysToNextYear() {
        const selectedBankHolidayIds = this.dataGrid.instance
            .getSelectedRowsData()
            .map(bankHoliday => bankHoliday['id']);
        this.bankHolidaysService
            .cloneBankHolidaysToNextYear(selectedBankHolidayIds)
            .subscribe(result => this.dataGrid.instance.refresh());
    }

    cellTemplate(container, options) {
        const noBreakSpace = '\u00A0';
        const text = (options.row.data.plants || []).map(element => options.column.lookup.calculateCellValue(element)).join(', ');
        container.textContent = text || noBreakSpace;
        container.title = text;
    }

    getValue(cellInfo) {
        if (cellInfo.value) {
            return cellInfo.value;
        }
    }

    onValueChanged(cellInfo, e) {
        cellInfo.setValue(e.value);
    }
}
