<div class="page__headline">
    <span eciTranslate="plant-calculation-cluster.title">Plant Calculation Cluster</span>
</div>

<div class="page__container plant-calculation-cluster_container">
    <eci-alert-error></eci-alert-error>
    <div>
    </div>
    <dx-data-grid id="dataGrid" #dataGrid
                  (onRowInserted)="successNotification()"
                  (onRowUpdated)="successNotification()"
                  (onRowUpdating)="onRowUpdating($event)"
                  (onEditorPrepared)="onEditorPreparing($event)"
                  (onInitNewRow)="onNewEntry($event)"
                  (onSelectionChanged)="onSelectionChanged()"
                  (onOptionChanged)="onOptionChanged($event)"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [cellHintEnabled]="true"
                  [columnAutoWidth]="true"
                  [dataSource]="dataSource"
                  [(selectedRowKeys)]="selectedRows"
                  [hoverStateEnabled]="true"
                  [remoteOperations]="false"
                  [showBorders]="true"
                  [showColumnLines]="true"
                  [showRowLines]="true"
                  [wordWrapEnabled]="true"
                  [keyExpr]="'id'">
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-pager
            [visible]="true"
            [allowedPageSizes]="[10, 20, 50, 100]"
            [showInfo]="true"
            [showPageSizeSelector]="true"
            [showNavigationButtons]="true">
        </dxo-pager>
        <dxo-pager [showInfo]="true"></dxo-pager>
        <dxo-state-storing
            [enabled]="true"
            type="custom">
        </dxo-state-storing>
        <dxo-selection
            [showCheckBoxesMode]="'always'"
            [allowSelectAll]="false"
            [selectAllMode]="'page'"
            [deferred]="false"
            mode="none"
        ></dxo-selection>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
        <dxo-editing
            [allowAdding]="true"
            [allowDeleting]="true"
            [allowUpdating]="true"
            [useIcons]="true"
            mode="row">
        </dxo-editing>

        <dxi-column [caption]="'plant-calculation-cluster.properties.isLudSelected' | translate"
                    allowEditing="true"
                    alignment="center"
                    dataField="ludSelected"
                    dataType="boolean">
        </dxi-column>

        <dxi-column [caption]="'plant-calculation-cluster.properties.isHueSelected' | translate"
                    allowEditing="true"
                    alignment="center"
                    dataField="hueSelected"
                    dataType="boolean">
        </dxi-column>

        <dxi-column [caption]="'plant-calculation-cluster.properties.isPilSelected' | translate"
                    allowEditing="true"
                    alignment="center"
                    dataField="pilSelected"
                    dataType="boolean">
        </dxi-column>

        <dxi-column [caption]="'plant-calculation-cluster.properties.isBloSelected' | translate"
                    allowEditing="true"
                    alignment="center"
                    dataField="bloSelected"
                    dataType="boolean">
        </dxi-column>

        <dxi-column [caption]="'plant-calculation-cluster.properties.isGrbSelected' | translate"
                    allowEditing="true"
                    alignment="center"
                    dataField="grbSelected"
                    dataType="boolean">
        </dxi-column>

        <dxi-column [caption]="'plant-calculation-cluster.properties.isRotSelected' | translate"
                    allowEditing="true"
                    alignment="center"
                    dataField="rotSelected"
                    dataType="boolean">
        </dxi-column>

        <dxi-column [caption]="'plant-calculation-cluster.properties.isSosSelected' | translate"
                    allowEditing="true"
                    alignment="center"
                    dataField="sosSelected"
                    dataType="boolean">
        </dxi-column>

        <dxi-column [caption]="'plant-calculation-cluster.properties.isRooSelected' | translate"
                    allowEditing="true"
                    alignment="center"
                    dataField="rooSelected"
                    dataType="boolean">
        </dxi-column>

        <dxi-column [caption]="'plant-calculation-cluster.properties.isVenSelected' | translate"
                    allowEditing="true"
                    alignment="center"
                    dataField="venSelected"
                    dataType="boolean">
        </dxi-column>

        <dxi-column [caption]="'plant-calculation-cluster.properties.isEchtSelected' | translate"
                    allowEditing="true"
                    alignment="center"
                    dataField="echtSelected"
                    dataType="boolean">
        </dxi-column>

        <dxi-column
            [caption]="'plant-calculation-cluster.properties.createdAt' | translate"
            dataField="createdAt"
            dataType="datetime">
        </dxi-column>

        <dxi-column
            [caption]="'plant-calculation-cluster.properties.updatedAt' | translate"
            dataField="updatedAt"
            dataType="datetime">
        </dxi-column>


        <div *dxTemplate="let data of 'totalGroupCount'">
            <div class="informer">
                <h2 class="count">{{ this.getTotalRowCount() }}</h2>
            </div>
        </div>
    </dx-data-grid>
</div>
