import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { EciTenantAwareLanguageService } from '@shared-components/shopsys-commons-ui';
import { CountryService } from '../../helpers/CountryService';
import { ArticleHistoryService } from './article-history.service';

@Component({
    selector: 'vltc-app-article-history',
    templateUrl: './article-history.component.html',
})
export class ArticleHistoryComponent implements OnInit {

    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    dataSource: DataSource;
    country: string;

    constructor(
        private articleHistoryService: ArticleHistoryService,
        public translateService: EciTenantAwareLanguageService,
        private countryService: CountryService,
    ) {
        this.countryService.getCountry.subscribe(c => this.country = c.name);
    }

    ngOnInit() {
        this.dataSource = this.articleHistoryService.createDataSource();
    }
}
