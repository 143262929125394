import { EciDxService } from '@shared-components/shopsys-commons-ui';
import DataSource from 'devextreme/data/data_source';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class VltJobService {

    constructor(
        private dxService: EciDxService,
        private httpClient: HttpClient,
    ) {
    }

    /**
     * Returns the Delivery Products DataSource.
     *
     * @returns dx-data source
     */
    createDeliveryProductDataSource(): DataSource {
        return this.dxService.createDataSource('api/delivery-products');
    }

    /**
     * Returns the Vlt Jobs DataSource
     */
    createVltJobDataSource(): DataSource {
        return this.dxService.createEditableDataSource(
            'api/vlt-jobs',
            'id',
        );
    }

    saveVltJob(priority: number, jobName: string, filterValue: string, deliveryTime: number, gridState: string,
               user: string, country: string, type: string): Observable<HttpResponse<object>> {

        const formData: FormData = new FormData();
        formData.append('priority', priority.toString());
        formData.append('name', jobName);
        formData.append('country', country);
        formData.append('filterValue', JSON.stringify(filterValue));
        formData.append('deliveryTime', deliveryTime.toString());
        formData.append('gridState', JSON.stringify(gridState));
        formData.append('createdBy', user);
        formData.append('type', type);

        return this.httpClient.post('api/vlt-jobs', formData,
            { observe: 'response' });
    }

    updateVltJob(priority: number, jobName: string, filterValue: string, deliveryTime: number,
                 gridState: string, type: string, id: any) {

        const vltJob: {
            priority: string;
            name: string;
            filterValue: string;
            deliveryTime: string;
            type: string;
            gridState: string
        } = {
            priority: priority.toString(),
            name: jobName,
            filterValue: JSON.stringify(filterValue),
            deliveryTime: deliveryTime.toString(),
            type,
            gridState: JSON.stringify(gridState),
        };

        return this.httpClient.put('api/vlt-jobs/' + id, vltJob,
            { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' });
    }
}
